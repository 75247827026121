.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  padding: 0px 4.5px;
  color: #000 !important;
}
.MuiAutocomplete-option {
  color: #000;
}
.MuiAutocomplete-inputRoot {
  height: 45px;
  display: flex;
  align-content: center;
  color: #000 !important;
}

.MuiTextField-root {
  border-radius: 5px;
}
.MuiAutocomplete-noOptions {
  display: none;
}
