*** Global *** body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  font-size: 1rem;
  font-weight: 400;
  color: white;
  background: -moz-linear-gradient(top, #6463ac 0%, #26aae1 100%);
  background: -webkit-linear-gradient(top, #6463ac 0%, #26aae1 100%);
  background: linear-gradient(to bottom, #6463ac 0%, #26aae1 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#6463ac', endColorstr='#26aae1', GradientType=0);
  background-attachment: fixed;
}

.containerBg {
  flex-grow: 1;
  width: 100%;
  min-height: 100vh;
  background: -moz-linear-gradient(top, #6463ac 0%, #26aae1 100%);
  background: -webkit-linear-gradient(top, #6463ac 0%, #26aae1 100%);
  background: linear-gradient(to bottom, #6463ac 0%, #26aae1 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#6463ac', endColorstr='#26aae1', GradientType=0);
  background-attachment: fixed;
}

.logo {
  margin: auto;
  width: 250px;
}

.root {
  width: 100%;
  max-height: 95vh;
}

a {
  color: white;
  text-decoration: underline;
}

a:hover {
  color: white;
  text-decoration: none;
}

/* *** Utilities *** */

.clickable {
  cursor: pointer;
}

.clickableDisabled {
  cursor: not-allowed;
  opacity: 50%;
}

.clickable:hover {
  filter: brightness(90%);
  text-decoration: underline;
  cursor: pointer;
}

.hideOnError {
  visibility: hidden;
}

.blur-text {
  color: transparent;
  text-shadow: 0 0 6px #000;
}

.lighten {
  opacity: 50%;
}

/* *** Public *** */

.avatarKnocker {
  min-width: 125px;
  max-width: 125px;
  min-height: 125px;
  max-height: 125px;
}

.avatarKnocker-card {
  min-width: 60px;
  max-width: 60px;
}

/* *** Views *** */

/* *** Constructors *** */

/* *** Components *** */

/* **** *** Loaders *** **** */

.component-loader-skeleton {
  width: 100%;
  height: 100%;
}

.component-loader-base {
  width: 100%;
  height: 100%;
  padding: 2px;
}

/* **** *** Container Constraints *** **** */

.data-constructor,
.data-constructor .MuiTableBody-root,
.data-container,
.data-controls-container,
.data-table-row {
  max-width: 1230px;
}

.data-app-bar,
.data-container .MuiTable-root.data-table,
.data-container .MuiTable-root.data-table.MuiTable-stickyHeader {
  max-width: 1230px;
}

.data-constructor table,
.data-constructor thead,
.data-constructor tr,
.data-constructor tbody,
.data-constructor td {
  max-width: 1230px;
}

.data-container table,
.data-container thead,
.data-container tr,
.data-container tbody,
.data-container td {
  max-width: 1230px;
}

/* *** *** Roles *** *** */

.data-container .system .bg {
  background-color: rgba(38, 170, 225, 0.5);
}

.data-container .system .fg {
  color: rgba(38, 170, 225, 1);
}

.data-container .system .bd {
  border: 1px solid rgba(38, 170, 225, 1);
}

.data-container .knocker .bg {
  background-color: rgba(226, 40, 133, 0.5);
}

.data-container .knocker .fg {
  color: rgba(226, 40, 133, 1);
}

.data-container .knocker .bd {
  border: 1px solid rgba(186, 186, 186, 0.9);
}

.data-container .user .bg {
  background-color: rgba(100, 99, 172, 0.3);
}

.data-container .user .fg {
  color: rgba(100, 99, 172, 0.5);
}

.data-container .user .bd {
  border: 1px solid rgba(100, 99, 172, 0.5);
}

/* *** *** Data *** *** */

div.data-container.root {
  width: 100%;
  height: 90vh;
}

.data-container .MuiSvgIcon-root {
  color: inherit;
  background: transparent;
}

.data-container .MuiSvgIcon-root {
  color: inherit;
  background: transparent;
}

.data-container .hoverDarkens:hover {
  filter: brightness(90%);
}

.data-container .hoverDarkens:hover {
  filter: brightness(90%);
}

.data-container .inactive {
  opacity: 0.5;
}

.data-container .placeholder {
  color: transparent;
  background: transparent;
  border-color: transparent;
}

/* *** *** Containers *** *** */

.data-table {
  margin-bottom: 0;
}

.data-container .table td,
.data-container .table th,
.data-container .table td,
.data-container .table th {
  border: 0 solid transparent;
}

.data-container .MuiTableRow-root {
  border-top: 1px solid rgba(186, 186, 186, 0.8);
}

.data-container .MuiTableBody-root {
  background-color: white;
}

/* *** *** Header *** *** */

.data-container .data-header .data-breadcrumbs {
  color: white;
}

.data-container .data-header .MuiChip-root {
  width: 190px;
  height: 37px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-left: 5px;
  background-color: rgba(38, 170, 225, 0.2);
  color: rgba(255, 255, 255, 1);
  padding-top: 3px;
  padding-bottom: 3px;
}

.data-header .MuiChip-root .MuiChip-avatar {
  height: 95%;
  width: 35px;
  padding: 5px;
}

.data-header .MuiChip-root .MuiChip-label {
  margin-left: 5px;
  font-size: 13px;
}

.data-header .MuiChip-root .MuiSvgIcon-root,
.data-header .MuiChip-root .MuiSvgIcon-root {
  height: 100%;
}

/* *** *** App Bar *** *** */

.data-app-bar .MuiAppBar-root,
.data-appbar .MuiAppBar-root {
  background-color: rgba(186, 186, 186, 0.5);
}

.data-app-bar .MuiIconButton-root,
.data-app-bar .MuiButton-root,
.data-app-bar .MuiButton-label {
  background-color: transparent;
  color: rgba(102, 102, 102, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.data-app-bar .MuiIconButton-root:hover,
.data-app-bar .MuiButton-root:hover {
  background-color: rgba(102, 102, 102, 0.1);
  color: inherit;
  filter: brightness(97%);
}

.data-app-bar .MuiIconButton-root {
  border-radius: 50%;
}

.data-app-bar .isActive {
  background-color: rgba(102, 102, 102, 0.1);
  color: rgba(102, 102, 102, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

/* *** *** Card Containers *** *** */

.data-container .clickable:hover,
.data-container .clickable.MuiTableRow-hover:hover {
  filter: brightness(80%);
  cursor: pointer;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.06);
}

.data-list-summary .data-container .data-table-row {
  height: 100%;
  min-height: 75px;
  max-height: 90px;
}

/* *** *** Cards *** *** */

.data-container .avatar {
  width: 70px;
  height: 70px;
}

.data-container .avatar.user .MuiSvgIcon-root {
  width: 90%;
  height: 90%;
}

.data-container .data-card-avatar-container {
  width: 75px;
  min-width: 75px;
  max-width: 75px;
  height: 100%;
}

.data-container .data-card-avatar-container.data-variant-populated {
  width: 75px;
  min-width: 75px;
  max-width: 75px;
  height: 100%;
}

.data-container .data-card .message.system .txtStaticVal {
  text-align: center;
  font-weight: 400;
}

.data-container .data-card div.data-card-detailed-placeholder {
  width: 50px;
}

.data-container .data-card .MuiChip-root {
  min-width: 70px;
  max-width: 100px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.04);
  padding: 0 20px 0 7px;
}

.data-container .data-card .chip-wider .MuiChip-root {
  width: 155px;
  min-width: 150px;
  max-width: 175px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.04);
  padding: 0 7px 0 7px;
}

.data-container .data-card .MuiChip-root {
  height: 27px;
}

.data-container .data-card .MuiChip-root .MuiChip-label,
.data-container .data-card .MuiChip-root .MuiSvgIcon-root,
.data-container .data-card .MuiChip-root .MuiChip-avatar {
  color: rgba(0, 0, 0, 0.3);
  background: transparent;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  height: 18px;
}

.data-container .data-card .chip-muted .MuiChip-root .MuiChip-label,
.data-container .data-card .chip-muted .MuiChip-root .MuiSvgIcon-root,
.data-container .data-card .chip-muted .MuiChip-root .MuiChip-avatar {
  color: rgba(0, 0, 0, 0.2);
  font-weight: 400;
}

.data-container .data-card .MuiChip-root .MuiSvgIcon-root,
.data-container .data-card .MuiChip-root .MuiChip-avatar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.data-container .data-card .MuiChip-root .MuiSvgIcon-root {
  width: 100%;
}

.data-container .data-card .MuiChip-root .MuiChip-label {
  margin-left: 2px;
  min-width: 40px;
  max-width: 60px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.data-container .data-card .chip-wider .MuiChip-root .MuiChip-label {
  margin-left: 2px;
  width: 120px;
  min-width: 100px;
  max-width: 130px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.data-container .data-card .MuiIconButton-colorSecondary {
  background-color: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.3);
  height: 27px;
  width: 27px;
  border: 0;
}

.data-container .data-card .MuiIconButton-colorSecondary:hover {
  background-color: rgba(0, 0, 0, 0.08);
  color: rgba(0, 0, 0, 0.5);
  border: 0;
}

.data-container .data-card .MuiIconButton-label {
}

.data-container .data-card .MuiSvgIcon-fontSizeSmall {
  height: 16px;
  width: 16px;
}

/* *** *** List > Shared *** *** */

.data-container .clickable:hover,
.data-container .clickable.MuiTableRow-hover:hover {
  filter: brightness(80%);
  cursor: pointer;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.06);
}

/* *** *** List > Summary Variant *** *** */

.data-list-summary .data-container .data-card {
  height: 100%;
  min-height: 75px;
  max-height: 75px;
}

.data-list-summary .data-card .data-card-list-results {
  height: 100%;
  min-height: 70px;
  max-height: 70px;
}

.data-list-summary .data-card .data-card-headline {
  font-weight: 400;
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.6);
}

.title2 {
  color: rgba(0, 0, 0, 0.6);
}

.data-list-summary .data-card .data-card-headline-secondary {
  margin-left: 0;
  padding-left: 0;
  color: rgba(0, 0, 0, 0.4);
}

/* *** *** List > Detailed Variant *** *** */

.data-container .data-card .message-admin-message-user,
.data-container .data-card .message-admin-message-knocker {
  font-size: 1rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.7);
}

.data-container .data-card .message-admin-message-system {
  font-size: 1rem;
  font-weight: 200;
  color: rgba(0, 0, 0, 0.7);
  display: block;
}

.data-container .data-container-list-detailed .dlistsummary .data-card-headline {
  width: 100%;
  height: 4.2em;
  min-height: 4.2em;
  max-height: 4.2em;
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.7);
}

.data-container .data-container-list-detailed .dlistsummary,
.data-container .data-container-list-detailed .dlistsummary .drow {
  height: 50px;
  min-height: 50px;
  max-height: 50px;
}

.data-container .data-container-list-detailed .dcard,
.data-container .data-container-list-detailed .data-card .drow {
  height: 100px;
  min-height: 100px;
  max-height: 100px;
}

.data-container .data-container-list-detailed .data-card .data-card-headline {
  width: 100%;
  height: 4.2em;
  min-height: 4.2em;
  max-height: 4.2em;
  font-weight: 600;
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.data-container .data-card .message-admin-message-user,
.data-container .data-card .message-admin-message-knocker {
  font-size: 1rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.7);
}

.data-container .data-card .message-admin-message-system {
  font-size: 1rem;
  font-weight: 200;
  color: rgba(0, 0, 0, 0.7);
  display: block;
  /*
    display: inline;
    display: inline-block;
     */
}

.data-container .data-container-list-detailed .message.system .txtStaticVal {
  text-align: center;
}

.data-container .data-container-list-detailed .MuiChip-root {
  max-width: 200px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.04);
  padding: 0 20px 0 7px;
}

.data-container .data-container-list-detailed .MuiChip-root {
  height: 27px;
}

.data-container .data-container-list-detailed .MuiChip-root .MuiChip-label,
.data-container .data-container-list-detailed .MuiChip-root .MuiSvgIcon-root,
.data-container .data-container-list-detailed .MuiChip-root .MuiChip-avatar {
  color: rgba(0, 0, 0, 0.3);
  background: transparent;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  height: 18px;
}

.data-container .data-container-list-detailed .MuiChip-root .MuiSvgIcon-root,
.data-container .data-container-list-detailed .MuiChip-root .MuiChip-avatar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.data-container .data-container-list-detailed .MuiChip-root .MuiSvgIcon-root {
  width: 100%;
}

.data-container .data-container-list-detailed .MuiChip-root .MuiChip-label {
  margin-left: 2px;
}

.data-card-list-results.data-card-list-results-summary .data-card-headline {
  min-height: 2rem;
  max-height: 2.5rem;
}

.data-container-list-summary .data-card .MuiChip-root {
  min-width: 80px;
  max-width: 150px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.04);
  padding: 0 20px 0 7px;
}

.data-container-list-summary .data-card .MuiChip-root .MuiChip-label {
  margin-left: 2px;
  min-width: 40px;
  max-width: 120px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

/* *** *** Controls *** *** */

/* Data container footer */

.MuiPaginationItem-root .MuiSvgIcon-root {
  color: white;
  background-color: transparent;
}

.data-footer .MuiChip-root {
  min-width: 75px;
  height: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(38, 170, 225, 0.9);
  color: rgba(255, 255, 255, 1);
  /*padding-top: 3px;*/
  /*padding-bottom: 3px;*/
}

.data-footer .MuiChip-root .MuiChip-avatar {
  height: 27px;
  width: 27px;
}

.data-footer .MuiChip-root .MuiChip-label {
  margin-left: 3px;
  margin-right: 5px;
}

.data-footer .MuiChip-root .MuiSvgIcon-root {
  height: 17px;
  width: 17px;
}

/* *** Feedback *** */

.MuiAlert-standardError {
  border: 1px solid #f44336;
  box-shadow: none;
}

.MuiAlert-standardError .MuiAlert-message {
  color: rgb(97, 26, 21);
}

.MuiAlert-standardError .MuiAlert-icon,
.MuiAlert-standardError .MuiSvgIcon-root {
  color: #f44336;
  box-shadow: none;
  background: transparent;
}

.components .MuiAlert-filledError {
  border: 1px solid #f44336;
  box-shadow: none;
}

.components .MuiAlert-filledError .MuiAlert-message {
  color: white;
}

.components .MuiAlert-filledError .MuiAlert-icon,
.components .MuiAlert-filledError .MuiSvgIcon-root,
.components .MuiAlert-filledError .MuiAlert-action {
  color: white;
  box-shadow: none;
  background: transparent;
}

.disabled .MuiTextField-root,
.disabled .MuiTextField-root:hover {
  opacity: 0.9;
  filter: brightness(93%);
  cursor: not-allowed;
}

/* Responsive */

/*
    Responsive breakpoints:
      xs: 0;
      sm: 576px;
      md: 768px;
      lg: 992px;
      xl: 1200px;
 */

/* Material-UI Large Breakpoint */
@media only screen and (max-width: 1919px) {
  .avatarKnocker {
    min-width: 80px;
    max-width: 80px;
    min-height: 80px;
    max-height: 80px;
  }

  .avatarKnocker-card {
    min-width: 50px;
    max-width: 60px;
  }

  .data-constructor,
  .data-constructor .MuiTableBody-root,
  .data-container,
  .data-controls-container,
  .data-table-row {
    max-width: 1230px;
  }

  .data-app-bar,
  .data-container .MuiTable-root.data-table,
  .data-container .MuiTable-root.data-table.MuiTable-stickyHeader {
    max-width: 1230px;
  }

  .data-constructor table,
  .data-constructor thead,
  .data-constructor tr,
  .data-constructor tbody,
  .data-constructor td {
    max-width: 1230px;
  }

  .data-container table,
  .data-container thead,
  .data-container tr,
  .data-container tbody,
  .data-container td {
    max-width: 1230px;
  }

  /*
    .data-container .data-card-avatar-container {
      width: 70px;
      min-width: 70px;
      max-width: 70px;
      height: 100%;
    }
    .data-container .data-card-avatar-container.data-variant-populated {
      width: 70px;
      min-width: 70px;
      max-width: 70px;
      height: 100%;
    }
    .data-container .avatar {
      min-width: 70px;
      max-width: 70px;
      min-height: 70px;
      max-height: 70px;
    }
    .data-card .data-card-headline {
      width: 100%;
      max-width: 1050px;
      height: 4.0em;
      min-height: 4.0em;
      max-height: 4.0em;
      font-weight: 400;
      font-size: 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }

     */
}

/* Material-UI Medium Breakpoint */
@media only screen and (max-width: 1219px) {
  .avatarKnocker-card {
    min-width: 45px;
    max-width: 55px;
  }

  .data-container .data-card-avatar-container {
    min-width: 20px;
    max-width: 65px;
    height: 100%;
  }

  .data-container .data-card-avatar-container.data-variant-populated {
    min-width: 20px;
    max-width: 65px;
    height: 100%;
  }

  .data-container .avatar {
    min-width: 20px;
    max-width: 65px;
    min-height: 20px;
    max-height: 65px;
  }

  .data-card .data-card-headline {
    width: 100%;
    min-width: 500px;
    max-width: 900px;
    /*min-height: 70px;*/
    max-height: 3rem;
    font-weight: 400;
    font-size: 1rem;
  }

  .data-constructor,
  .data-constructor .MuiTableBody-root,
  .data-container,
  .data-controls-container,
  .data-table-row {
    width: 100%;
    min-width: 500px;
    max-width: 1200px;
  }

  .data-app-bar,
  .data-container .MuiTable-root.data-table,
  .data-container .MuiTable-root.data-table.MuiTable-stickyHeader {
    width: 100%;
    min-width: 950px;
    max-width: 1200px;
  }

  .data-constructor table,
  .data-constructor thead,
  .data-constructor tr,
  .data-constructor tbody,
  .data-constructor td {
    width: 100%;
    min-width: 950px;
    max-width: 1200px;
  }

  .data-container table,
  .data-container thead,
  .data-container tr,
  .data-container tbody,
  .data-container td {
    width: 100%;
    min-width: 950px;
    max-width: 1200px;
  }
}

/* Material-UI Small Breakpoint */
@media only screen and (max-width: 959px) {
  .avatarKnocker-card {
    min-width: 0;
    max-width: 45px;
  }

  .data-container .data-card-avatar-container {
    min-width: 0;
    max-width: 50px;
    min-height: 0;
    visibility: hidden;
  }

  .data-container .data-card-avatar-container.data-variant-populated {
    min-width: 0;
    max-width: 50px;
    height: 100%;
    /*visibility: hidden;*/
  }

  .data-container .avatar {
    min-width: 0;
    max-width: 50px;
    min-height: 0;
    max-height: 50px;
    /*visibility: hidden;*/
  }

  .data-card .data-card-headline {
    width: 100%;
    min-width: 250px;
    max-width: 800px;
    /*min-height: 70px;*/
    max-height: 2.4rem;
    font-weight: 400;
    font-size: 1rem;
    text-align: center;
    justify-content: center;
    align-content: center;
  }

  .MuiCard-root .MuiCardHeader-root {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .MuiCard-root .MuiCardContent-root {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .data-constructor,
  .data-constructor .MuiTableBody-root,
  .data-container,
  .data-controls-container,
  .data-table-row {
    width: 100%;
    min-width: 250px;
    max-width: 900px;
  }

  .data-app-bar,
  .data-container .MuiTable-root.data-table,
  .data-container .MuiTable-root.data-table.MuiTable-stickyHeader {
    width: 100%;
    min-width: 250px;
    max-width: 900px;
  }

  .data-constructor table,
  .data-constructor thead,
  .data-constructor tr,
  .data-constructor tbody,
  .data-constructor td {
    width: 100%;
    min-width: 250px;
    max-width: 900px;
  }

  .data-container table,
  .data-container thead,
  .data-container tr,
  .data-container tbody,
  .data-container td {
    width: 100%;
    min-width: 250px;
    max-width: 900px;
  }
}

/* Material-UI X-Small Breakpoint */
@media only screen and (max-width: 599px) {
  .MuiTypography-h4,
  h4 {
    font-size: 1.8rem;
    line-height: 1.235;
  }

  .avatarKnocker-card {
    min-width: 30px;
    max-width: 35px;
  }

  .data-container .data-card-avatar-container {
    min-width: 0;
    max-width: 20px;
    height: 100%;
    visibility: hidden;
  }

  .data-container .data-card-avatar-container.data-variant-populated {
    min-width: 0;
    max-width: 20px;
    height: 100%;
    visibility: hidden;
  }

  .data-container .avatar {
    min-width: 0;
    max-width: 20px;
    min-height: 0;
    max-height: 20px;
    visibility: hidden;
  }

  .data-card .data-card-headline {
    width: 100%;
    min-width: 100px;
    max-width: 470px;
    /*min-height: 70px;*/
    max-height: 2rem;
    font-weight: 400;
    font-size: 1rem;
    text-align: center;
    justify-content: center;
    align-content: center;
  }

  .MuiCard-root .MuiCardHeader-root {
    padding-top: 0;
    padding-bottom: 0;
  }

  .MuiCard-root .MuiCardContent-root {
    padding-top: 0;
    padding-bottom: 0;
  }

  .data-constructor,
  .data-constructor .MuiTableBody-root,
  .data-container,
  .data-controls-container,
  .data-table-row {
    width: 100%;
    min-width: 100px;
    max-width: 550px;
  }

  .data-app-bar,
  .data-container .MuiTable-root.data-table,
  .data-container .MuiTable-root.data-table.MuiTable-stickyHeader {
    width: 100%;
    min-width: 100px;
    max-width: 550px;
  }

  .data-constructor table,
  .data-constructor thead,
  .data-constructor tr,
  .data-constructor tbody,
  .data-constructor td {
    width: 100%;
    min-width: 100px;
    max-width: 550px;
  }

  .data-container table,
  .data-container thead,
  .data-container tr,
  .data-container tbody,
  .data-container td {
    width: 100%;
    min-width: 100px;
    max-width: 550px;
  }
}

/* *** Animations *** */

/* Outer Glow Animations */
.targetElementGlow {
  animation: glowBlend 6000ms ease-out 500ms 3 alternate;
  background: inherit;
  border-color: inherit;
  box-shadow: 0 0 0 #f2f4f6, inset 0 0 0 #f2f4f6, 0 0 0 #f2f4f6;
  outline: inherit;
}

.targetElementGlow:focus::-webkit-input-placeholder {
  color: #f2f4f6;
}

.targetElementGlow:focus:-moz-placeholder {
  color: #f2f4f6;
}

@keyframes glowLight {
  0% {
    border-color: #26aae1;
    box-shadow: 0 0 10px rgba(38, 170, 225, 0.2), inset 0 0 5px rgba(38, 170, 225, 0.1),
      0 2px 0 #f2f4f6;
  }
  100% {
    border-color: #26aae1;
    box-shadow: 0 0 20px rgba(38, 170, 225, 0.8), inset 0 0 10px rgba(38, 170, 225, 0.5),
      0 2px 0 #f2f4f6;
  }
}

@keyframes glowDark {
  0% {
    border-color: #6463ac;
    box-shadow: 0 0 10px rgba(100, 99, 172, 0.2), inset 0 0 5px rgba(100, 99, 172, 0.1),
      0 2px 0 #f2f4f6;
  }
  100% {
    border-color: #6463ac;
    box-shadow: 0 0 20px rgba(100, 99, 172, 0.8), inset 0 0 10px rgba(100, 99, 172, 0.5),
      0 2px 0 #f2f4f6;
  }
}

@keyframes glowBlend {
  0% {
    border-color: #d9dbdd;
    box-shadow: 0 0 25px rgba(38, 170, 225, 0.8), inset 0 0 12px rgba(38, 170, 225, 0.4),
      0 1px 0 rgba(217, 219, 221, 0.1);
  }
  33% {
    border-color: #d9dbdd;
    box-shadow: 0 0 25px rgba(38, 170, 225, 0.1), inset 0 0 12px rgba(38, 170, 225, 0.4),
      0 1px 0 rgba(38, 170, 225, 0.8);
  }
  66% {
    border-color: #d9dbdd;
    box-shadow: 0 0 25px rgba(100, 99, 172, 0.1), inset 0 0 12px rgba(100, 99, 172, 0.4),
      0 1px 0 rgba(100, 99, 172, 0.8);
  }
  100% {
    border-color: #d9dbdd;
    box-shadow: 0 0 25px rgba(100, 99, 172, 0.8), inset 0 0 12px rgba(100, 99, 172, 0.4),
      0 1px 0 rgba(217, 219, 221, 0.1);
  }
}

/* *** Debugger *** */
.components-debugger .MuiSvgIcon-root {
  background-color: transparent;
  color: inherit;
}

.components-debugger .MuiPaper-root {
  color: inherit;
}

.components-debugger .MuiAccordion-root,
.components-debugger .MuiAccordionSummary-root {
  color: inherit;
  background-color: inherit;
}

.components-debugger .MuiCollapse-container {
  background-color: rgba(255, 255, 255, 0.9);
}

.components-debugger .MuiAccordionDetails-root {
  background-color: rgba(255, 255, 255, 0.9);
  color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.components-debugger .MuiAccordionDetails-root .debugger-results-container {
  padding: 15px;
}
