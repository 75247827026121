/*
    @todo: deprecate this entire file
 */

/* *** Utilities *** */

/* *** Public *** */

.talkBubble {
  position: relative;
  width: 350px;
  height: 85px;

  padding: 15px;
  border-radius: 10px;
  border: solid white;
  background: white;

  color: black;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.talkBubble:before {
  content: '';
  position: absolute;
  right: 100%;
  top: 26px;
  width: 0;
  height: 0;

  border-top: 13px solid transparent;
  border-right: 26px solid white;
  border-bottom: 13px solid transparent;
}

.textInput {
  width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 30px;
  height: 30px;
  color: white;
}

.badgeCircle {
  height: 30px;
  width: 30px;
  background-color: #26aae1;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconCircle {
  height: 40px;
  width: 40px;
  background-color: #bababa;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: brightness(97%);
}

.iconContainer {
  height: 40px;
  width: 40px;
  background-color: transparent;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

h6 a {
  color: black;
  text-decoration: underline;
}

h6 a:hover {
  color: black;
  text-decoration: none;
}

.formAdornmentWrapper {
  background-color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 55px;
  height: 45px;
  padding: 0px;
  margin: 0px;
  position: absolute;
  right: 1px;
  padding-right: 5px;
}

.formAdornment {
  background-color: white;
}

.gc-cs-link {
  color: white;
  text-decoration: none;
}

.legalLinks {
  color: #26aae1;
  text-decoration: underline;
}

/* Material-UI. */
.MuiFilledInput-inputMarginDense,
.MuiFilledInput-marginDense,
.MuiFilledInput-adornedEnd,
.MuiFilledInput-inputAdornedEnd {
  background-color: white;
}

.MuiTable-root .MuiSkeleton-root,
.MuiCard-root .MuiSkeleton-text,
.MuiCard-root .MuiSkeleton-circle {
  background-color: rgba(0, 0, 0, 0.04);
  filter: brightness(75%);
  color: transparent;
  opacity: 0.75;
}

.MuiCardActions-root .MuiChip-colorPrimary {
  background-color: #d9dbdd;
}

.expand {
  transform: rotate(0deg);
  margin-left: auto;
  color: #666666;
}

.expandOpen {
  transform: rotate(180deg);
  color: #666666;
  background-color: rgba(0, 0, 0, 0.04);
}

.disabled .MuiTextField-root,
.disabled .MuiTextField-root:hover {
  opacity: 0.9;
  filter: brightness(93%);
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .talkBubble {
    position: relative;
    width: 200px;
    height: 85px;
  }
}
